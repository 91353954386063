import React, { useState } from 'react'
import PT from 'prop-types'
import { useResponsive } from 'ahooks'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons'
import {
  includes, map, pluck
} from 'ramda'

import Link from 'Components/link'
import { useStore } from 'App'

const Column = ({
  brandName, brand, activePub, children
}) => {
  const responsive = useResponsive()
  const [brandOpen, setBrandOpen] = useState(false)
  const store = useStore()
  const { free } = store.mst.publication
  const freeSiteCodes = pluck('code')(free)

  if (brandName !== 'Legacy Research') {
		return null;
	}

  if (!brand) {
    return null
  }

  const Simple = () => (<h6>{brandName}</h6>)
  const Menu = () => (
    <>
      <button onClick={() => setBrandOpen(!brandOpen)}>{brandOpen
        ? <FontAwesomeIcon icon={faChevronUp} size='sm' />
        : <FontAwesomeIcon icon={faChevronDown} size='sm' />}&nbsp; {brandName}
      </button>
      {brandOpen && <span>{children}</span>}
    </>
  )
  const Adjunct = responsive.xl ? Simple : Menu

  const items = map((item) => {
    if (includes(item.code)(freeSiteCodes)) {
      return null
    }

    return (
      <Link
        role='menuitem'
        to={`/${item.code}`}
        key={item._id}
        className={activePub === item.title ? 'dropdown-item-lrg-selected' : 'dropdown-item-lrg'}
      >
        <span>{item.title}</span>
      </Link>
    )
  })(brand)

  if (!children) {
    return (
      <div className='column'>
        <Adjunct />
        {items}
      </div>
    )
  }
  return (
    <div className='column'>
      <Adjunct>
        {items}
      </Adjunct>
    </div>
  )
}

Column.propTypes = {
  brandName: PT.string,
  brand: PT.arrayOf(PT.shape({
    _id: PT.string,
    title: PT.title,
    code: PT.code
  })),
  activePub: PT.string,
  children: PT.object
}

export default Column
